<template>
  <div v-show="showModal" class="modal">
    <div class="modal__overlay"></div>
    <div class="modal__body">
      <!-- <button @click="hideModal" class="modal__close" v-if="isAcct === false">
        <i class="fa fa-close"></i>
      </button> -->
      <h2 class="modal__title">Getcoins online user agreement</h2>
      <div class="modal__content">
        <p>
          Evergreen ATM LLC, a Nevada Limited Liability Company, also known and
          operating under the trade name and of &ldquo;GetCoins&rdquo; and
          operating with use of the web address with the Uniform Resource
          Locator (URL) of &ldquo;www.getcoins.com&rdquo; (herein referred to
          collectively as &ldquo;GetCoins&rdquo;, the &ldquo;Company&rdquo;,
          "Us," "We," &ldquo;Its,&rdquo; or "Our") provides this web site,
          www.getcoins.com (the "Site") and all site-related services and
          products, including, without limitation, any associated websites
          (including bitcoinatmmearme.com), APIs, mobile applications GetCoins
          kiosks, and related transactions and functionality (collectively, the
          "Service") subject to Your acceptance and compliance with the
          Agreement and conditions set forth in this Online User Agreement (the
          "Agreement").
        </p>
        <p>&nbsp;</p>
        <p>
          This Agreement governs the relationship between GetCoins and You, the
          Site visitor ("You", &ldquo;Your&rdquo;) with respect to Your use of
          the Service. It is important that You read carefully and understand
          the Agreement and conditions of this Agreement.
        </p>
        <p>&nbsp;</p>
        <p>We reserve the right at any time to:</p>
        <p>&nbsp;</p>
        <ul>
          <li>
            Change the Agreement, including any conditions of this Agreement;
          </li>
          <li>
            Change the Service, including terminating, eliminating,
            supplementing, modifying, adding to or
          </li>
        </ul>
        <p>
          &nbsp;&nbsp;&nbsp; discontinuing any content or data on or feature of
          the Service;
        </p>
        <ul>
          <li>
            Change any fees or charges, if any, related to Your use of the
            Service; and
          </li>
          <li>
            Change the equipment, hardware or software required to provide or
            use and access the Service.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          Any changes We make will be effective immediately upon notice, which
          We may provide by any means including, without limitation, posting on
          the Site. Your continued use of the Site or Service after such notice
          will be deemed acceptance of any changes. Be sure to return to this
          page periodically to ensure familiarity with the most current version
          of this Agreement. Upon Our request, You agree to sign a
          non-electronic version of this Agreement.
        </p>
        <p>&nbsp;</p>
        <p>
          By registering for a GetCoins Online User Account ("Account"), You
          agree that You have read, understood, and accepted all of the terms
          and conditions contained in this Agreement and the GetCoins Privacy
          Policy, which is incorporated into this Agreement by this reference,
          as though set forth in full. Please read this document carefully.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong
            >IF YOU DO NOT ACCEPT AND AGREE TO THE AGREEMENT DO NOT ACCESS
            OR</strong
          >
        </p>
        <p>
          <strong>USE THE SITE AND DO NOT ACCESS OR USE THE SERVICE.</strong>
        </p>
        <p>&nbsp;</p>
        <p><strong>Your Account; Registration</strong></p>
        <p>&nbsp;</p>
        <p>
          GetCoins conducts and executes exchanges directly with You, without
          the involvement of any third party. No intermediaries are
          involved.&nbsp; We never hold any currency, whether fiat, sovereign or
          digital, on Your behalf, for Your benefit, or in Your Account.
        </p>
        <p>&nbsp;</p>
        <p>
          This Site is for Your personal, non-commercial use only. We are
          vigilant about maintaining the security of Our Site and the Service.
          By registering with Us, You agree to provide Us with current,
          accurate, and complete information about Yourself as prompted by the
          registration process, and to keep such information updated.
        </p>
        <p>&nbsp;</p>
        <p>
          In order to register with Us, You must provide Us with certain
          required pertinent information, and any information We request for the
          purposes of identity verification in order to perform Anti-Money
          Laundering checks. We may decide to verify Your identity and/or the
          identity of Your legal entity at any time using third party
          verification providers. We may at Our sole discretion refuse to allow
          You access to the Site and/or the Service if We are not satisfied that
          You comply with all applicable identification verification
          requirements.
        </p>
        <p>&nbsp;</p>
        <p>
          If You open an Account as a business entity, an agent representing
          Your legal entity will be required to have full authorization to enter
          into binding agreement on the Platform on behalf of Your legal entity.
          We reserve the right to request proof of such authorization.
        </p>
        <p>&nbsp;</p>
        <p>
          By opening and registering an Account with Us You warrant that You
          have read and accepted the terms and conditions of this Agreement, the
          Terms of Use (found on our webstite:
          <a href="http://www.getcoins.com">www.getcoins.com</a>) and our
          Privacy Policy (found on our webstite:
          <a href="http://www.getcoins.com">www.getcoins.com</a>), you have full
          capacity to accept these terms and conditions, and enter into the
          desired transactions. &nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          In order to establish an Account and use the GetCoins Service, as
          defined in this Agreement, You must first register by providing any
          required information and documents, and accepting the terms and
          conditions of this Agreement. GetCoins may, in Its sole discretion,
          refuse to allow You to establish an Account, or limit the number of
          Accounts that a single user may establish and maintain at any time.
          The creation or use of multiple accounts without obtaining Our prior
          express permission may result in the suspension of Your account or
          accounts, as well as all pending purchase/sale orders/offers. Any
          attempt to create or use an account without obtaining Our prior
          express permission (or to assist others in any such attempt), or to
          distribute instructions, software or tools for that purpose, will
          result in Your Account(s) being terminated. Termination is not the
          exclusive remedy for such a violation, and, in addition, We may elect
          to seek any and all legal and/or equitable remedies available.
        </p>
        <p>&nbsp;</p>
        <p>
          You acknowledge that You are solely responsible for maintaining the
          confidentiality of Your Account information, including Your password,
          safeguarding Your own digital currency, and for all activity including
          transactions that are posted to Your Account.
        </p>
        <p>&nbsp;</p>
        <p>
          Depending on the total value of the transaction, or cumulative
          transactions, or, if there is unusual or suspected suspicious activity
          related to Your account, We may request additional information from
          You, including corroborating and/or authenticating documents, and may
          freeze (suspend) any transaction(s) pending Our review. You are
          obligated to comply with any requests for additional information, or
          accept termination of Your Account, as well as any related fees and/or
          charges. You are required to notify us immediately of any unauthorized
          use of Your Account or password, or any other breach of security. All
          such notifications are to be made by email addressed to
          <a href="mailto:support@getcoins.com">support@getcoins.com</a> .
        </p>
        <p>&nbsp;</p>
        <p>
          You agree that You will not use the Service to perform any Prohibited
          Activity (see below), including any criminal activity of any sort,
          including but not limited to, money laundering, illegal gambling
          operations, terrorist financing, or malicious hacking.
        </p>
        <p>&nbsp;</p>
        <p>
          To be eligible to use the GetCoins Service (as defined herein), and to
          link a funding method or to purchase or sell digital currency to
          GetCoins, You must be at least 18 years old (or the applicable age of
          majority and contractual capacity in the state in which you reside).
          If You are under the age of 18 and You wish to engage in digital
          currency purchase or sale activity, please contact Us at
          <a href="mailto:support@getcoins.com">support@getcoins.com</a> .
        </p>
        <p>&nbsp;</p>
        <p>
          Your Account includes conversion services that allow You to buy and/or
          sell supported digital currency (e.g. Bitcoin or Ethereum)
          ("Conversion Services"), subject to the GetCoins Conversion Rate (i.e.
          the price of a supported digital currency, as quoted by GetCoins). You
          acknowledge that the quoted Conversion Rate may not be the same as the
          &ldquo;Spot Rate&rdquo; or the &ldquo;buy&rdquo; price or
          &ldquo;sell&rdquo; price at any specific time, and that GetCoins may
          add a margin or &ldquo;spread&rdquo; to the quoted Conversion Rate.
          You agree that You will not use any account other than Your own, or
          access the account of any other GetCoins customer(s) (whether
          authorized or unauthorized) at any time, or assist others in doing so.
        </p>
        <p>&nbsp;</p>
        <p>
          GetCoins reserves the right to delay any Conversion Services
          transaction if it believes perceives a risk of fraud or illegal
          activity. GetCoins does not guarantee the availability of its Service,
          and the act of purchasing supported digital currency from GetCoins
          does not result in a guarantee that You may sell Your supported
          digital currency to GetCoins.
        </p>
        <p>&nbsp;</p>
        <p>
          The risk of loss in trading or holding digital currency can be
          substantial. You should therefore carefully consider whether trading
          or holding digital currency is suitable for You in light of Your
          financial condition. See &ldquo;Risks&rdquo;, below.
        </p>
        <p>&nbsp;</p>
        <p><strong>Limited License</strong></p>
        <p><strong>&nbsp;</strong></p>
        <p>
          We grant You a limited, nonexclusive, nontransferable license, subject
          to the terms and conditions of this Agreement, to access and use the
          Site, and related content, materials, information (collectively, the
          "Content") solely for approved purposes as permitted by GetCoins from
          time to time. Any other use of the
        </p>
        <p>
          Site or the Content is expressly prohibited and all right, title and
          interest to or in the Site or the Content is exclusively the property
          of GetCoins. You agree You will not copy, transmit, distribute, sell,
          license, disseminate, reverse engineer, modify, publish, or
          participate in the transfer or sale of, create derivative works from,
          or in any other way exploit any of the Content, in whole or in part.
        </p>
        <p>&nbsp;</p>
        <p><strong>Prohibited Activities</strong></p>
        <p>&nbsp;</p>
        <p>
          In connection with Your access to or use of the Site, Service and Your
          Account, You specifically agree that You will not: (1) violate any
          law, statute, ordinance or regulation; (2) attempt to defraud or scam
          anyone; (3) provide false or inaccurate information or documents; or,
          (4) transact with, or behalf of, individuals, companies and countries
          that are on prescribed sanctions lists; and (5) take any action that
          could impose an unreasonable or disproportionately large load or
          stress on Our infrastructure or detrimentally interfere with, disrupt,
          intercept, compromise, destroy or expropriate any system data or
          information.
        </p>
        <p>&nbsp;</p>
        <p>
          We reserve the right at all times to monitor, review, retain and/or
          disclose any information, as appropriate and necessary to satisfy any
          applicable law, regulation or legal and/or governmental request. We
          reserve the right to cancel and/or suspend Your Account and/or block
          transactions or freeze funds immediately and without notice if We
          determine, in Our sole discretion, that Your Account is associated
          with a use, enterprise or business that is contrary to this
          &ldquo;Prohibited Activity&rdquo; provision.
        </p>
        <p>&nbsp;</p>
        <p><strong>Transactions Limits</strong></p>
        <p>&nbsp;</p>
        <p>
          The use of the Service is subject to a limit on the US Dollar volume
          You may transact or transfer in a given period (e.g., daily, monthly,
          annually). Your transaction limits may vary depending on Your assessed
          risk level, geographic location, transaction history, payment method,
          verification steps You have completed, and other factors. GetCoins
          reserves the right to change applicable limits as We deem necessary in
          Our sole discretion. If You wish to raise Your transaction limits We
          may require You to submit additional information about Yourself or
          Your business, provide records, and arrange for meetings or telephonic
          interviews with the GetCoins Compliance Department staff (i.e. as a
          form of Enhanced Due Diligence). GetCoins reserves the right to charge
          You costs and fees associated with any such Enhanced Due Diligence
          required, provided that We notify You in advance of any such fees
          and/or costs. In Our sole discretion, We may refuse to raise Your
          limits or We may lower Your limits at any time.
        </p>
        <p>&nbsp;</p>
        <p><strong>Purchase Transactions</strong></p>
        <p>&nbsp;</p>
        <p>
          After successful registration, You may purchase supported virtual
          currency online from GetCoins by linking a valid payment method, which
          may include wire transfers, ACH, or the use of a payment company. You
          authorize GetCoins to initiate debits from Your payment method(s) in
          settlement of purchase transactions. A Conversion Fee (as defined
          herein) applies to all purchase transactions. Although GetCoins will
          attempt to deliver supported virtual currency to You as promptly as
          possible, You acknowledge that funds will be debited from Your
          selected payment method before virtual currency is delivered to Your
          designated digital wallet or bank account, as appropriate.
        </p>
        <p>&nbsp;</p>
        <p>
          You must trade Your entire Account deposit amount within 48 hours of
          the deposit being approved. Any untraded funds will be returned to the
          account it originated from, less $30 in bank fees.&nbsp; We will make
          Our best effort to fulfill all transactions, but in the rare
          circumstance where time has changed conversion rates, or, GetCoins
          cannot fulfill Your purchase order at the original conversion rate
          quoted, We will notify You and seek Your approval to fulfill the
          purchase order at the then contemporaneous Conversion Rate. To secure
          the performance of Your obligations under this Agreement, You grant to
          GetCoins a lien on and security interest in and to the balances on
          Your account. All purchases of digital currency are nonrefundable. Any
          wires that must be returned for any reason will be charged 5%
          administrative and service fee.
        </p>
        <p>&nbsp;</p>
        <p><strong>Sale Transactions</strong></p>
        <p>&nbsp;</p>
        <p>
          After successful registration, You may sell GetCoins supported virtual
          currency online. An applicable Conversion Fee (as defined herein)
          applies to all sale transactions.
        </p>
        <p>&nbsp;</p>
        <p>
          When selling virtual currency, You must carefully check and confirm
          the type of virtual currency and bank details You provide to Us. Once
          both parties have agreed to proceed with the trade, We will provide
          You with Our wallet address to which You must transfer the virtual
          currency. You must make all reasonable efforts to ensure that the
          transfer is made immediately upon agreeing to trade with Us. We have
          no obligation to fulfil Your order if We do not receive the full
          amount of the agreed virtual currency within the specified time agreed
          to. You must:
        </p>
        <ol>
          <li>
            a)&nbsp;select the type of virtual currency You wish to sell (as
            relevant);
          </li>
        </ol>
        <p>
          (b) select the amount You wish to sell and request a quote (We only
          make payments in USD);
        </p>
        <p>
          (c)&nbsp;await Our decision as to whether We wish to make You an offer
          to trade; and
        </p>
        <p>
          (d)&nbsp;if You accept Our offer, press the confirm button and this
          will fix the trading price at which the trade will be completed.
        </p>
        <p>
          The price which We offer You will depend upon the price at which We
          can buy or sell the relevant virtual currency at such time. You must
          make all reasonable efforts to send Us the virtual currency
          immediately. We will transfer a sum equal to the trading price to You
          in full cleared funds as soon as reasonably possible once We have
          received the virtual currency from You.
        </p>
        <p>
          The time taken for You to receive payment for your virtual currency
          will depend on the payment type and method you have agreed to, and may
          take up to three or more business days.
        </p>
        <p>&nbsp;</p>
        <p><strong>Conversion Fees</strong></p>
        <p>&nbsp;</p>
        <p>
          Each Conversion Service transaction (i.e. Purchase Transaction and
          Sale Transaction) is subject to a fee (a "Conversion Fee"). The
          applicable Conversion Fee is displayed to You on the GetCoins Site
          prior to You completing a transaction. GetCoins will not process a
          transaction if the Conversion Fee and any other associated fees as may
          apply, such as wire transfer fees, exceed the value of Your
          transaction. GetCoins, in its sole discretion, may waive some portion
          of the Conversion Fee, depending on the payment method You select. The
          availability of each Payment Method depends on a number of factors,
          including but not limited to Your location, the identification
          information You have provided to Us, and limitations imposed by third
          party payment processors, as and if applicable.
        </p>
        <p>&nbsp;</p>
        <p>
          GetCoins reserves the right to adjust Its Conversion Fees and any
          applicable waivers at any time. We will always notify You of the
          Conversion Fee which applies to Your transaction when You authorize
          the transaction and in each receipt We issue to You.
        </p>
        <p>&nbsp;</p>
        <p><strong>Reversals; Cancellations</strong></p>
        <p>&nbsp;</p>
        <p>
          You cannot cancel, reverse, or change any transaction marked as
          complete or pending. If Your payment is not successful or if Your
          payment method has insufficient funds, You authorize GetCoins, in its
          sole discretion, either to cancel the transaction or to debit Your
          other payment methods, in any amount necessary to complete the
          transaction. You are responsible for maintaining an adequate balance
          and/or sufficient credit limits in order to avoid overdraft, NSF fees,
          or similar fees charged by Your payment provider. GetCoins reserves
          the right to refuse to process, or to cancel or reverse, any purchases
          or sales of digital currency in its sole discretion, even after funds
          have been debited from Your account(s), if GetCoins (1) suspects the
          transaction involves, or has a high risk of involvement in, money
          laundering, terrorist financing, fraud, human trafficking, a scam, or
          any other type of financial crime; (2) in response to a subpoena,
          court order, or other government order; or, (3) if GetCoins suspects
          the transaction relates toa Prohibited Activity. In such instances,
          GetCoins will reverse the transaction and We are under no obligation
          to allow You to reinstate a purchase or sale order at the same price
          or on the same terms or rate as the cancelled transaction.
        </p>
        <p>&nbsp;</p>
        <p><strong>Denial of Service</strong></p>
        <p>&nbsp;</p>
        <p>
          We reserve the right to: (1) change prices without prior notice; (2)
          deny the Service to anyone accessing the Service using anonymity
          tools, IP hiding and all those that hide identification and/or origin;
          and, (3) deny the Service to anyone who, in Our sole discretion, does
          not demonstrate an acceptable level of trust.
        </p>
        <p>&nbsp;</p>
        <p>
          Without any liability of any kind to You, We reserve the right to
          modify, suspend or terminate the Service, in whole or in part, at any
          time and without notice to You. Also, We may change this Agreement at
          any time.
        </p>
        <p>&nbsp;</p>
        <p><strong>Your Information</strong></p>
        <p>&nbsp;</p>
        <p>
          In order to use the Service, including transfers of virtual currency
          and/or government-issued currency (i.e. fiat currency), You may be
          required to provide GetCoins with certain personal information,
          including, but not limited to, your name, address, cellular telephone
          number, e-mail address, date of birth, Social Security Number, and
          information regarding Your bank account (e.g., financial institution,
          account type, routing number, and account number). In submitting this
          or any other personal information, You warrant and represent that the
          information You provide is accurate, and You agree to update any
          changes in the information provided to GetCoins. You hereby authorize
          GetCoins to, directly or through third parties make any inquiries We
          consider necessary to verify the information You provide and/or
          protect against fraud, including to query identity information
          contained in public reports (e.g., Your name, address, past addresses,
          or date of birth), to query account information associated with Your
          linked bank account (e.g., name or account balance), and to take
          action We deem reasonably necessary based on the results of such
          inquiries and reports. You further authorize any and all third parties
          to which such inquiries or requests may be directed to fully respond
          to such inquiries or requests. This includes authorizing Your wireless
          operator (i.e. any wireless cellular operator) to use Your mobile
          number, name, address, email, network status, customer type, customer
          role, billing type, &nbsp;mobile device identifiers (IMSI and IMEI)
          and other subscriber status details, if available, solely to allow
          verification of Your identity and to compare information You have
          provided to GetCoins with Your wireless operator account profile
          information for the duration of the time You have an account with, or
          are registered with, GetCoins.
        </p>
        <p>&nbsp;</p>
        <p>
          We are committed to protecting Your privacy and to limiting the
          collection of information from You, to that information which is
          reasonably necessary for Us to safely provide You with access to the
          Site, and the Service. We do not sell or rent Your data or personal
          information to any third party. If We believe that suspicious or
          illegal conduct has taken place or is about to take place, We may
          share Your information with governmental agencies and/or law
          enforcement.
        </p>
        <p>&nbsp;</p>
        <p>
          Data security is extremely important to Us. We have taken prudent,
          reasonable measures to ensure that Your personal data will be
          protected against unauthorized or accidental access, processing or
          erasure. We maintain Our commitment to data security by implementing
          appropriate physical, electronic and managerial systems and
          procedures.
        </p>
        <p>&nbsp;</p>
        <p><strong>Risks</strong></p>
        <p>&nbsp;</p>
        <p>
          The trading or purchase and sale of virtual or digital currencies,
          including Bitcoin, involves significant risks. Prices and exchange
          rates (e.g. Conversion Rates and Conversion Fees) can and do fluctuate
          continuously. Because of these fluctuations, the value of your virtual
          or digital currencies, including Bitcoin, may increase or decrease at
          any given moment. The value may decrease to zero making your virtual
          or digital currency, including Bitcoin, worthless. Digital currency
          trading also has special risks not generally shared with fiat or
          sovereign currencies.
        </p>
        <p>&nbsp;</p>
        <p>
          Digital and virtual currency trading also has special risks not
          generally shared with official currencies or goods or commodities in a
          market. Unlike a currency that is backed by a government, or by
          commodities, like gold or silver, a digital or virtual currency is
          backed by technology and trust. As a result, and by way of example,
          there is no central bank that can take corrective measures to protect
          the value of digital or virtual currency in a crisis. Instead, a
          digital or virtual currency is a largely unregulated, worldwide system
          of currency firms and individuals, that relies on peer-to-peer
          networking and cryptography to maintain its integrity.
        </p>
        <p>&nbsp;</p>
        <p>
          Digital and virtual currency trading and purchasing are susceptible to
          irrational as well as rational influences, bubbles, or the loss of
          confidence which could affect or even collapse demand relative to
          supply. For example, confidence might collapse because of changes
          imposed by the software developers or others, a government crackdown
          or change in policy, the creation of superior competing alternative
          currencies, a global pandemic, or a deflationary or inflationary
          spiral. Confidence might also collapse because of technical problems,
          the compromise of the system, theft or loss, the intrusion by hackers,
          or government action that could, for example, prevent transactions
          from settling or impose a fee on transactions.
        </p>
        <p>&nbsp;</p>
        <p>
          You should also know that there may be additional risks not foreseen
          or identified in this Agreement. You should carefully assess whether
          Your financial situation and tolerance for risk is suitable for buying
          or trading digital or virtual currency. Only use funds that You are
          willing to lose.
        </p>
        <p>&nbsp;</p>
        <p>
          We use Our proprietary processes and payment and banking providers to
          receive Your funds to make and accept payments. Neither Our banking
          providers or payment processors transfer, purchase or sell digital or
          virtual currency.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong
            >&nbsp;&nbsp;&nbsp; WE DO NOT GUARANTEE OR REPRESENT THAT YOU WILL
            PROFIT IN ANY WAY FROM TRADING, PURCHASING OR SELLING DIGITAL OR
            VIRTUAL CURRENCY OR BY ACCESS TO OR USE OF THE SERVICE. BY ACCESSING
            OR USING THE SERVICE, YOU ARE ACCEPTING FULLY AND EXCLUSIVELY THE
            RISKS OUTLINED ABOVE.</strong
          >
        </p>
        <p>&nbsp;</p>
        <p><strong>Website Accuracy</strong></p>
        <p>&nbsp;</p>
        <p>
          Although We intend to provide accurate and timely information on the
          Site, the Site (including, without limitation, the Content) may not
          always be entirely accurate, complete or current and may also include
          technical inaccuracies or typographical errors. In an effort to
          continue to provide You with as complete and accurate information as
          possible, information may be changed or updated from time to time
          without notice, including without limitation information regarding Our
          policies, products and services. Accordingly, You should verify all
          information before relying on it, and all decisions based on
          information contained on the Site are Your sole responsibility and We
          shall have no liability for such decisions. Links to third parties, or
          third party materials (including without limitation, websites) may be
          provided as a convenience but are not controlled by Us. You
          acknowledge and agree that We are not responsible for any information,
          content, or services contained in any third party materials or on any
          third party sites accessible or linked to the Site.
        </p>
        <p>&nbsp;</p>
        <p><strong>Computer Viruses</strong></p>
        <p>&nbsp;</p>
        <p>
          We shall not bear any liability, whatsoever, for any damage or
          interruptions caused by any computer viruses, spyware, ransom ware,
          scareware, Trojan horses, worms or other malware that may affect Your
          computer or other equipment, or any phishing, spoofing or other
          attack. We advise the regular use of a reputable and readily available
          virus screening and prevention software. You should also be aware that
          SMS and email services are vulnerable to spoofing and phishing attacks
          and should use care in reviewing messages purporting to originate from
          GetCoins. Always log into your Account through the Site to review any
          transactions or required actions if You have any uncertainty regarding
          the authenticity of any communication or notice.
        </p>
        <p>&nbsp;</p>
        <p><strong>Operation of Digital Currency Protocols</strong></p>
        <p>&nbsp;</p>
        <p>
          GetCoins does not own or control the underlying software protocols
          which govern the operation of digital currencies supported on Our
          platform. In general, the underlying protocols are open source and
          anyone can use, copy, modify, and distribute them. By using the
          GetCoins platforms and softwares, You acknowledge and agree (1) that
          GetCoins is not responsible for operation of the underlying protocols
          and that GetCoins makes no guarantee of their functionality, security,
          or availability; and (2) that the underlying protocols are subject to
          sudden changes in operating rules (a/k/a &ldquo;forks&rdquo;), and
          that such forks may materially affect the value, function, and/or even
          the name of the digital currency you store in Your digital wallet(s).
          In the event of a fork, You agree that GetCoins may temporarily
          suspend GetCoins operations (with or without advance notice to You)
          and that GetCoins may, in its sole discretion, (a) configure or
          reconfigure its systems or (b) decide not to support (or cease
          supporting) the forked protocol entirely. You acknowledge and agree
          that GetCoins assumes no responsibility whatsoever with regard or in
          connection with an unsupported branch of a forked protocol.
        </p>
        <p>&nbsp;</p>
        <p><strong>Termination</strong></p>
        <p><strong>&nbsp;</strong></p>
        <p>
          You may terminate this Agreement with Us, and close Your Account at
          any time, following settlement of any pending transactions.
        </p>
        <p>&nbsp;</p>
        <p>
          You also agree that We may, in our sole discretion, terminate Your
          access to or use of the Site or Service, or access to Your Account,
          including without limitation, Our right to: limit, suspend or
          terminate the Service and Your Account(s); prohibit access to the
          Service and its - Content, services and tools; delay or remove hosted
          content; take technical and legal steps to keep You from accessing or
          using the Service if We believe anything You are doing creates any
          danger, potential harm or risk to the Service or others using the
          Service, or creates the risk of any legal liabilities, infringes on
          the intellectual property &nbsp;rights of third-parties, or is
          inconsistent with the letter or spirit of this Agreement.
          Additionally, We may, in appropriate circumstances and at Our
          discretion, suspend or terminate Your Account or accounts for any
          reason, including without limitation: (1) attempts to gain
          unauthorized access to the Service, Site or an account not your own or
          providing assistance to others attempting to do so, (2) overcoming
          software security features limiting use of or protecting any content,
          (3) using the Service to perform any Prohibited Activity, or and
          illegal activities such as money laundering, illegal gambling
          operations, financing terrorism, or other criminal activities, (4)
          violating this Agreement, (5) failing to pay or fraudulent payment for
          transactions, (6) unexpected operational difficulties, or (7) upon the
          request of law enforcement or other government agencies, if deemed to
          be legitimate and compelling by Us, acting in Our sole discretion.
        </p>
        <p>&nbsp;</p>
        <p>
          If We suspend Your Account, or terminate Your use of the Site or
          Service for any reason, We will
        </p>
        <p>
          provide You with notice of Our action unless a court order or other
          legal process prohibits Us from
        </p>
        <p>
          providing You with such notice. You acknowledge that Our decision to
          take certain actions, including
        </p>
        <p>
          limiting access to, suspending, or closing Your Account, may be based
          on confidential criteria that are essential to Our risk management and
          security protocols. You agree that We are under no obligation to
          disclose the details of Our risk assessment, risk management and/or
          security procedures to You.
        </p>
        <p>&nbsp;</p>
        <p><strong>Warranty</strong></p>
        <p>&nbsp;</p>
        <p>
          The Site and Service are provided without warranty of any kind, either
          express or implied. We do not represent or warrant that the Site or
          Service will be available at all times to meet Your needs or that the
          Site or Service will not be interrupted or delayed, even during a
          transaction. We do not guarantee that access will not be interrupted,
          or that there will be no delays, failures, errors, omissions or loss
          of transmitted information.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong
            >THE SITE AND SERVICE IS PROVIDED "AS IS" AND WITHOUT ANY</strong
          >
        </p>
        <p>
          <strong
            >REPRESENTATION OR WARRANTY, EXPRESS, IMPLIED OR STATUTORY.</strong
          >
        </p>
        <p>&nbsp;</p>
        <p>
          We will use reasonable efforts to ensure that the Site and Service can
          normally be accessed by You in accordance with these Agreement. We may
          suspend use of the Site and/or Service for maintenance or
          modifications and We will make reasonable efforts to give You notice
          of any such outage. However, You acknowledge that this may not be
          reasonably practicable or even possible.
        </p>
        <p>&nbsp;</p>
        <p><strong>No Financial Advice</strong></p>
        <p>&nbsp;</p>
        <p>
          We do not provide any financial, securities, commodities or investment
          advice in connection with the Service. As a courtesy, or in the course
          of communication, We may provide information on the price, range,
          volatility of virtual currency and events that have affected or may
          affect the price of virtual currency, but this is not to be considered
          or construed as financial, securities, commodities or investment
          advice. Any decision to purchase or sell virtual currency is Your sole
          decision and We will not be responsible or liable for any loss
          suffered.
        </p>
        <p>&nbsp;</p>
        <p><strong>Release; Indemnification</strong></p>
        <p>&nbsp;</p>
        <p>
          In connection with any claims of loss related to use of the Site or
          the Service, You release GetCoins, its members, officers, directors,
          employees, agents, representatives, affiliates and service providers,
          and each of their respective officers, directors, agents, joint
          venturers, employees and representatives from any and all claims,
          known or unknown, and any demands and damages (actual and
          consequential) of every kind and nature arising out of or in any way
          connected with such claims or losses, and/or any related disputes. You
          agree to indemnify and hold GetCoins, its affiliates and service
          providers, and each of its or their respective members, officers,
          directors, agents, joint venturers, employees and representatives,
          harmless from any claim or demand (including attorneys' fees and any
          fines, fees or penalties imposed by any regulatory authority) arising
          out of or related to Your breach of this Agreement or Your violation
          of any law, rule or regulation, or the rights of any third party.
        </p>
        <p>&nbsp;</p>
        <p><strong>Limitation of Liability</strong></p>
        <p>&nbsp;</p>
        <p>
          To the fullest extent permitted by the law, We shall not be held
          liable for any damages, losses or
        </p>
        <p>
          liabilities, financial or otherwise, that You may incur in connection
          with Your access to or use of the
        </p>
        <p>
          Site and/or Service, including any loss of profits, or special,
          incidental or consequential damages.
        </p>
        <p>
          When accessing or using the Site or Service, You are fully responsible
          to comply with all laws and
        </p>
        <p>
          regulations applying in the jurisdiction in which the access or use
          occurs.&nbsp; If You are the victim of a scam or fraud, You
          acknowledge and agree that Your use of the Site or the Service does
          not mean that GetCoins was involved or facilitated the scam or fraud.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong
            >IN NO EVENT SHALL WE BE LIABLE FOR ANY LOST PROFITS OR ANY
            SPECIAL,</strong
          >
        </p>
        <p>
          <strong
            >INCIDENTAL ORCONSEQUENTIAL DAMAGES ARISING OUT OF OR IN
            CONNECTION</strong
          >
        </p>
        <p>
          <strong
            >WITH YOUR ACCESS TO OR USE OF THE SITE OR SERVICE, THE BREACH OF
            THIS</strong
          >
        </p>
        <p><strong>AGREEMENT OR OUR RELATIONSHIP WITH YOU.</strong></p>
        <p>&nbsp;</p>
        <p>
          You agree to indemnify, defend and hold harmless Us from any claim,
          lawsuit, or demand (including
        </p>
        <p>
          any attorneys' fees we incur), arising out of Your breach of these
          Agreement or Your access to or use of the Site or Service. If you
          believe that We have made an error in the provision of the Site or
          Service, You are required to notify us of any such error via email
          addressed to
          <a href="mailto:support@getcoins.com">support@getcoins.com</a>
          &nbsp;within ten (10) business days of the date of the transaction
          which You claim was in error. Your failure to provide Us with the
          required notice will constitute a waiver of any claim You may have.
          Within ninety (90) days of receiving notice, We will resolve Your
          claim by either correcting the error or by explaining to You why We
          believe no error was committed; any pending transactions may be
          suspended during this period.
        </p>
        <p>&nbsp;</p>
        <p><strong>Anti-Money Laundering</strong></p>
        <p>&nbsp;</p>
        <p>
          Courts and jurisdictions are divided on whether or not digital and
          virtual currencies are a currency.
        </p>
        <p>
          Accordingly, digital and virtual currencies may or may not be
          &ldquo;money&rdquo; and, may or may not be &ldquo;currency&rdquo;, and
          therefore, not subject to anti-money laundering statutes. We have
          developed and You agree to abide by Our anti-money laundering policy
          which is designed to meet strict standards, including the existence of
          systems and controls to mitigate the risk presented to any person who
          accesses or uses the Service. This includes, but is not limited to:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            Establishing robust internal policies, procedures and controls that
            strive to combat any
          </li>
        </ul>
        <p>
          &nbsp;&nbsp; attempted use of GetCoins for illegal or illicit purposes
          and to ensure our customers basic
        </p>
        <p>&nbsp;&nbsp; protections under consumer protection laws;</p>
        <p>&nbsp;</p>
        <ul>
          <li>
            Complying with the applicable regulations and guidance set forth by
            the Financial Crimes
          </li>
        </ul>
        <p>&nbsp; Enforcement Network (&ldquo;FinCEN&rdquo;);</p>
        <p>&nbsp;</p>
        <ul>
          <li>Filing Suspicious Activity Reports (&ldquo;SARs&rdquo;);</li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>Filing Currency Transaction Reports (&ldquo;CTRs&rdquo;);</li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>
            Maintaining comprehensive records of orders and other transfers;
          </li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>
            Appointing a Chief Compliance Officer who will be responsible for
            the implementation and
          </li>
        </ul>
        <p>&nbsp; oversight of our BSA/AML Program;</p>
        <p>&nbsp;</p>
        <ul>
          <li>
            Executing Know Your Customer (&ldquo;KYC&rdquo;) procedures on all
            customers;
          </li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>
            Performing regular, independent audits of our BSA/AML Program;
          </li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>Following record retention requirements; and</li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>
            Implementing a formal and ongoing compliance training program for
            all new and existing
          </li>
        </ul>
        <p>&nbsp; employees.</p>
        <p>&nbsp;</p>
        <p>
          Our Customer Identity Program (&ldquo;CIP&rdquo;) is an important part
          of our BSA/AML Program, and helps us
        </p>
        <p>
          detect suspicious activity in a timely manner, and prevent fraud. In
          accordance with this, in order to
        </p>
        <p>
          open an Account, Your identity must be verified, authenticated, and
          checked against government
        </p>
        <p>
          watch-lists, including the Office of Foreign Assets Control
          (&ldquo;OFAC&rdquo;). If You are an individual, prior
        </p>
        <p>
          to opening an account, We will collect, verify and authenticate the
          following information:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>Email address;</li>
          <li>Mobile phone number;</li>
          <li>Full legal name;</li>
          <li>
            Social Security Number (&ldquo;SSN&rdquo;) or any comparable
            identification issued by government;
          </li>
          <li>Date of birth (&ldquo;DOB&rdquo;);</li>
          <li>
            Proof of identity (e.g., driver&rsquo;s license, passport or
            government-issued ID);
          </li>
          <li>Home address (not a mailing address or P.O. Box); and</li>
          <li>
            Additional information or documentation at the discretion of our
            Compliance Team.
          </li>
          <li>
            Non-US Citizen Customers will be required to provide proof of
            address (e.g., utility bill).
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          If You are ana business customer, We will collect, verify and
          authenticate the following
        </p>
        <p>information:</p>
        <p>&nbsp;</p>
        <ul>
          <li>Legal name;</li>
          <li>
            Employer Identification Number (&ldquo;EIN&rdquo;) or any comparable
            identification number
          </li>
        </ul>
        <p>&nbsp;&nbsp; issued by government;</p>
        <ul>
          <li>
            Full legal name (of all bank account signatories and beneficial
            owners);
          </li>
          <li>Email address (of all account signatories);</li>
          <li>Mobile contact phone number (of all account signatories);</li>
          <li>
            Address (principal place of business and/or other physical
            location);
          </li>
          <li>
            Proof of legal existence (e.g., state certified articles of
            incorporation or certificate of
          </li>
        </ul>
        <p>
          &nbsp;&nbsp; formation, unexpired government-issued business license,
          trust instrument or other comparable
        </p>
        <p>&nbsp;&nbsp; legal documents as applicable);</p>
        <ul>
          <li>
            Contract information of owners/principals/executive management (as
            applicable);
          </li>
          <li>
            Proof of identity (e.g., driver&rsquo;s license, passport or
            government-issued ID) for each
          </li>
        </ul>
        <p>
          &nbsp;&nbsp; individual beneficial owner that owns 25% or more, as
          well as all account signatories; and
        </p>
        <ul>
          <li>
            Identifying information for each entity beneficial owner that owns
            25% or more (see
          </li>
        </ul>
        <p>
          &nbsp;&nbsp; individual customer information collected above for more
          details).
        </p>
        <ul>
          <li>
            If your institution successfully meets and completes our CIP
            requirements and neither it
          </li>
        </ul>
        <p>
          &nbsp;&nbsp; nor any of its owners, principals, executive, or managers
          appear on OFAC or any other
        </p>
        <p>
          &nbsp;&nbsp; governmental watchlist, we will provide you with account
          opening agreements electronically.
        </p>
        <p>&nbsp;</p>
        <p>
          We also file required reports with the U.S. Treasury (i.e.&nbsp;
          FinCEN) if We know, suspect or have reason to suspect suspicious
          activities have occurred. A suspicious transaction is often one that
          is inconsistent with a customer&rsquo;s known and legitimate business,
          personal activities or personal means. Our compliance department
          monitors transaction to help identify unusual patterns of customer
          activity. In addition, all currency transactions over a determined
          amount are required to be reported to the US government (i.e. FinCEN).
          Our compliance department maintains records and supporting
          documentation of all reports filed.
        </p>
        <p>&nbsp;</p>
        <p>
          All records are retained for five (5) years and are readily available
          upon official request by an
        </p>
        <p>applicable examiner, regulator, or law enforcement agency.</p>
        <p>&nbsp;</p>
        <p><strong>Taxes</strong></p>
        <p>&nbsp;</p>
        <p>
          It is Your responsibility to determine what, if any, taxes may be due
          as a result of Your access to or use of the Service. It is entirely
          Your responsibility to report and remit the appropriate tax to the
          proper taxing authority. We are not responsible for determining
          whether any tax is due or for collecting, reporting or remitting any
          tax arising from Your access to or use of the Service.
        </p>
        <p>&nbsp;</p>
        <p><strong>Disclaimer</strong></p>
        <p>&nbsp;</p>
        <p>
          Transactions involving digital and virtual currency are irreversible,
          so always make sure to check the receiving address before initiating a
          transaction. We highly recommend You only transact with individuals
          You know. We are in no way responsible for, among other things,
          losses, failures or problems caused by, related to, or arising from
          the technology used by digital and virtual currencies. We suggest You
          get advice from an independent, competent person or organization
          before You purchase any digital or virtual currency. The success of
          those who traded or purchased/sold any digital or virtual currency in
          the past is no indication of future success.
        </p>
        <p>&nbsp;</p>
        <p><strong>Governing Law and Venue</strong></p>
        <p>&nbsp;</p>
        <p>
          This Agreement shall be governed by the laws the United States of
          America. The venue for any action or proceeding initiated shall be the
          the State of Nevada, the United States of America. If any action at
          law or in equity is brought to enforce or interpret the provisions of
          this Agreement and services provided herein, the prevailing party in
          such action shall be entitled to all reasonable costs to include
          actual attorney fees.
        </p>
        <p>&nbsp;</p>
        <p><strong>Severability</strong></p>
        <p>&nbsp;</p>
        <p>
          In the event that any term or provision of these Agreement is held by
          a court of competent jurisdiction to be illegal, unenforceable or
          invalid in whole or in part for any reason, the remaining provisions
          of this Agreement shall remain in full force and effect.
        </p>
        <p>&nbsp;</p>
        <p><strong>Interpretation</strong></p>
        <p>&nbsp;</p>
        <p>
          The format, words and phrases used in these Agreement shall have the
          meaning generally understood in the digital currency industry. This
          Agreement shall be construed in accordance with its fair meaning and
          not against the drafting party.
        </p>
        <p>&nbsp;</p>
        <p><strong>Force Majeure</strong></p>
        <p>&nbsp;</p>
        <p>
          We shall not be liable for delays, failure in performance or
          interruption of service which result directly or indirectly from any
          cause or condition beyond our reasonable control, including but not
          limited to, any delay or failure due to any act of God, pandemic, act
          of civil or military authorities, act of terrorists, civil
          disturbance, war, strike or other labor dispute, fire, interruption in
          telecommunications or Internet services or network provider services,
          failure of equipment and/or software, other catastrophe or any other
          occurrence which is beyond Our reasonable control and shall not affect
          the validity and enforceability of any remaining provisions.
        </p>
        <p>&nbsp;</p>
        <p>&copy; 2020 Evergreen ATM LLC (GetCoins). All Rights Reserved.</p>
        <p>&nbsp;</p>
        <p>Revised: October 30, 2020</p>
      </div>
      <div class="modal__action">
        <!-- <div class="form-group mb-0" v-if="isAcct === false || hideToggle === true">
          <label class="toggle">
            <input class="toggle-checkbox" type="checkbox" v-model="dontShow" />
            <span class="toggle-switch"></span>
            <span class="toggle-label">I agree</span>
          </label>
        </div> -->
        <div class="form-group mb-0" v-if="isAcct === false">
          <label class="checkbox my-3">
            <input
              class="checkbox__check"
              type="checkbox"
              v-model="acceptTerms"
            />
            <span class="checkbox__checkmark"></span>
            <span class="toggle-label">I agree</span>
          </label>
        </div>
        <button
          class="btn btn-primary"
          :disabled="acceptTerms === false && isAcct === false"
          @click="hideModal"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    isAcct: {
      type: Boolean,
      default: false,
    },
    hideToggle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dontShow: false,
    acceptTerms: false,
  }),
  methods: {
    hideModal() {
      this.$emit("hide");
    },
  },
  watch: {
    acceptTerms(val) {
      localStorage.setItem("hideTerms", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  display: block;
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__content {
    max-height: 500px;
    overflow-y: scroll;
  }

  &__body {
    width: 700px;
    max-width: 100%;
    border-radius: 10px;
    background-color: var(--body-bg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 20px 10px;

    .modal__close {
      position: absolute;
      right: 20px;
      top: 20px;
      background-color: var(--primary);
      color: #fff;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: none;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;
    margin: 15px 0;
  }

  &__action {
    margin: 20px 0;
  }
}
ul {
  padding-left: 20px;
  li {
    list-style: disc;
  }
}
</style>